.tour-wrapper {
  background: rgb(24, 11, 11);
  height: 40rem;
  min-height: 40rem;
  width: 100vw;
}

.header-wrapper {
  display: flex;
  margin: 0 auto;
  margin-left: 10vw;
  margin-right: 10vw;
}

.bg-image {
  background-image: url(/assets/running-in-forrest.jpg);
  background-repeat: no-repeat;
  background-position: top center ;
  padding-bottom: 20rem;
  width: 80rem;
  min-width: 80rem;
  height: 40rem;
  min-height: 40rem;
  
}

.tour-header {
  color: white;
  margin-left: 2rem;
}

.nav {
  display: flex;
}

.nav a {
  color: white;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: none;
  font-size: 1.3rem;
}

.nav-middle {
  margin-left: 12rem;
  margin-top: 0rem;
}

.nav-right {
  display: flex;
  justify-content: space-around;
  margin-top: 0rem;
  margin-left: 20rem;
}

.nav-right a {
  font-size: 1.2rem;
  margin-right: 0px;
}

.nav-middle a:hover,
.nav-right a:hover {
  text-decoration: underline;
}

.header-text-wrapper {
  margin-top: 5rem;
  margin-bottom: 0px;
  font-size: 3rem;
  color: white;
  margin-left: 2rem;
}

.header {
  margin: 0px;
  margin-top: 10rem;
}

.sub-header {
  margin-left: 2rem;
  color: white;
  margin-top: 0px;
}

.chevron-down {
  background-image: url(/assets/chevron-down.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 1rem;
  margin-top: 0.3rem;
  margin-left: 0.1rem;
}

.tour-information-wrapper {
  margin: 0 auto;
  width: 80rem;
  height: 40rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
}

.tour-information-left {
  display: flex;
  flex-direction: column;
}

.info-wrapper {
  margin-left: 14rem;
  margin-top: 3rem;
  display: flex;
  margin-right: 8rem;
}

.info-symbol {
  background-image: url(/assets/info-symbol.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 5rem;
  margin-top: 2rem;
  margin-left: 0.2rem;
}

.clock-wrapper {
  margin-left: 14rem;
  margin-top: 1rem;
  display: flex;
}

.clock-symbol {
  background-image: url(/assets/clock-symbol.png);
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
  margin-right: 0.1rem;
  margin-left: 0.2rem;
}

.people-wrapper {
  margin-left: 14rem;
  margin-top: 1rem;
  display: flex;
}

.people-symbol {
  background-image: url(/assets/people-symbol.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 4rem;
  margin-right: 1rem;
  margin-left: 0.4rem;
}

.description-wrapper {
  font-size: 1.4rem;
  font-weight: bold;
}

.decent-text {
  font-size: 1.2rem;
  font-weight: 500;
}

.clock-text {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  padding-right: 26.6rem;
}

.people-text {
  font-size: 1.2rem;
  font-weight: 500;
}

.note-wrapper {
  margin-left: 14rem;
  margin-top: 1rem;
  display: flex;
  margin-right: 8rem;
}

.note-symbol {
  background-image: url(/assets/note-symbol.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 5rem;
  margin-right: 1.3rem;
}

.target-wrapper {
  margin-left: 14rem;
  margin-top: 1rem;
  display: flex;
  margin-right: 8rem;
}

.target-symbol {
  background-image: url(/assets/target-symbol.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 5rem;
  margin-right: 1rem;
  margin-left: 0.2rem;
}

.tour-information-right {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.call-to-action {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid grey;
  margin-left: 2rem;
  width: 20rem;
}

.price {
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: 0rem;
  margin-bottom: 0.8rem;
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-tiny {
  font-size: 1.1rem;
}

.bookingButton {
  background-color: #7289da;
  width: 10rem;
  color: white;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.3rem;
  margin-top: 3rem;
  outline: none;
  border: none;
}

.social-media-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 0.4rem;
  width: 24rem;
}

.heart-symbol {
  background-image: url(/assets/heart.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 5rem;
}

.heart-symbol:hover {
  background-image: url(/assets/heart-red-hover-over-half-filled.png);
}

.heart-symbol:active {
  background-image: url(/assets/heart-red-filled.png);
}

.favorites {
  font-size: 1.3rem;
  font-weight: 600;
}

.avatar_dracarys {
  background-image: url(/assets/avatar_dracarys.png);
  background-repeat: no-repeat;
  width: 30rem;
  height: 13rem;
  margin-right: 1rem;
  margin-left: 5rem;
  margin-top: 2rem;
}

.avatar_reeves {
  background-image: url(/assets/avatar_reeves.png);
  background-repeat: no-repeat;
  width: 30rem;
  height: 13rem;
  margin-right: 1rem;
  margin-left: 5rem;
  margin-top: 2rem;
}

.avatar-text {
  margin-left: 7rem;
  font-size: 1.3rem;
  font-weight: 600;
}
.tiny {
    margin-left: 2rem;
    text-align: center;
}

.description-header {
  margin-left: 1.2rem;
}

.guide-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem;
}