.addTourHeader {
  background-color: white;
  height: 70vh;
}

.addTourNav {
  display: flex;
  justify-content: space-between;
}

.addTourDropdown {
  display: flex;
  margin-right: 3rem;
}

.addTourWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tourTitle {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.title {
  width: 45rem;
  margin: 1rem 0;
  padding: 1.5rem 0;
  background-color: white !important;
  color: black;
  padding-left: 0.5rem;
}

.title::placeholder {
  font-size: 1.6rem;
  font-weight: bold;
  color: black;
  padding-left: 0.5rem;
}

.category {
  width: 10rem;
  color: black;
  background: white;
  padding-left: 0.5rem;
}

.category::placeholder {
  color: black;
  padding-left: 0.5rem;
  font-weight: 500;
}

.addThumbnail {
  background-image: url(/assets/AddThumbnail.PNG);
  background-repeat: no-repeat;
  width: 100px;
  padding: 100px;
  margin: 0 2rem 0 0;
}

.addTourAboutWrapper {
  margin-top: 21rem;
  display: flex;
  justify-content: center;
}

.addTourAbout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 4rem;
  width: 60%;
  /* margin: 2rem; */
}

.info {
  display: flex;
  align-items: center;
}

.infoSymbol {
  background-image: url(/assets/info-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
}

.tourDescription {
  margin-bottom: 1rem;
  padding-left: 10px;
  width: 21.5rem;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  outline: none;
}

.clock {
  display: flex;
  align-items: center;
}

.clockSymbol {
  background-image: url(/assets/clock-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
}

.tourLength {
  margin-bottom: 1rem;
  width: 21.5rem;
  padding-left: 10px;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  outline: none;
}

.people {
  display: flex;
  align-items: center;
}

.peopleSymbol {
  background-image: url(/assets/people-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
}

.recommendedAge {
  margin-bottom: 1rem;
  width: 21.5rem;
  padding-left: 10px;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  outline: none;
}

.note {
  display: flex;
  align-items: center;
  padding: 0 !important;
  border: none !important;
}

.noteSymbol {
  background-image: url(/assets/note-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
  margin-left: -0.4rem;
  margin-right: 0.4rem;
}

.itemsToBring {
  margin-bottom: 1rem;
  padding-left: 10px;
  width: 21.5rem;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  outline: none;
}

.target {
  display: flex;
  align-items: center;
}

.targetSymbol {
  background-image: url(/assets/target-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
}

.tourAddress {
  margin-bottom: 1rem;  
  width: 21.5rem;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  outline: none;
}

.btnAddTour {
  margin-right: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-left: 3.9rem;
  }

.btnCancel {
  border-radius: 5px;
  padding-left: 15rem;
  padding-right: 3rem;
  font-size: 1.2rem;
}

.addTourInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tourPrice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 4rem;
}

.tourPriceInput {
  width: 2rem;
  color: black;
  padding-left: 0.3rem;
  background: #f2f2f2;
  outline: none;
  border: none;
}

.aboutGuide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.guideImage {
  background-image: url(/assets/avatar_dracarys.png);
  background-repeat: no-repeat;
  width: 15rem;
  height: 15rem;
  margin: 8rem 0 0 3rem;
}