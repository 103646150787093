.showTourList {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
}

.card-background-forrest {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url('/assets/running-in-forrest.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: bolder;
  margin: 1rem;
  width: 15rem;
  height: 15rem;
}

/* .card-background-canyons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url('/assets/pedro-lastra-137923-unsplash.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: bolder;
  margin: 1rem;
  width: 15rem;
  height: 15rem;
}

.card-background-rocks{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url('/assets/mountains2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: bolder;
  margin: 1rem;
  width: 15rem;
  height: 15rem;
} */

.card-background:hover {
  cursor: pointer;
}

.paperbin-icon {
  background-image: url('/assets/paperbin-white.png');
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  width: 2.5rem;
  height: 2.4rem;
  background-position: 95% 5%;
  margin-left: 12rem;
  margin-bottom: 8.3rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
 -ms-user-select: none;
}

.heart-icon {
  background-image: url('/assets/heart.PNG');
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  width: 2.5rem;
  height: 2.4rem;
  background-position: 95% 5%;
  margin-left: 12rem;
  margin-bottom: 8.3rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
 -ms-user-select: none;
}

.heart-icon:hover {
  background-image: url('/assets/heart-red-hover-over-half-filled.png')
}

.heart-icon:active {
    background-image: url('/assets/heart-red-filled.png')
}

.background {
  background: rgba(0,0,0,0.4);
}
