@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mountain-background-card {
  background-image: url(/assets/mountains3.png);
  background-repeat: no-repeat;
  background-position: left;
  width: 70rem;
  min-width: 30rem;
  height: 60rem;
  min-height: 60rem;
  background-position: 15% 49%;
}

.poppins-font {
  font-family: 'Poppins', cursive;
}

.main-color-blue {
  color: #7289da;
}

.info {
  padding-top: 2rem;
}

.linker:hover {
  cursor: pointer;
}

.checkbox-warning-filled
  [type='checkbox'][class*='filled-in']:checked
  + label:after {
  border-color: #ff8800;
  background-color: #ff8800;
}

.search-button {
  background-color: #7289da;
  width: 10rem;
  color: white;
  height: 3rem;
  font-size: 1.3rem;
  margin-top: 3rem;
  outline: none;
  border: none;
}
.showTourList {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
}

.card-background-forrest {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url('/assets/running-in-forrest.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: bolder;
  margin: 1rem;
  width: 15rem;
  height: 15rem;
}

/* .card-background-canyons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url('/assets/pedro-lastra-137923-unsplash.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: bolder;
  margin: 1rem;
  width: 15rem;
  height: 15rem;
}

.card-background-rocks{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url('/assets/mountains2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: bolder;
  margin: 1rem;
  width: 15rem;
  height: 15rem;
} */

.card-background:hover {
  cursor: pointer;
}

.paperbin-icon {
  background-image: url('/assets/paperbin-white.png');
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  width: 2.5rem;
  height: 2.4rem;
  background-position: 95% 5%;
  margin-left: 12rem;
  margin-bottom: 8.3rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
 -ms-user-select: none;
}

.heart-icon {
  background-image: url('/assets/heart.PNG');
  background-repeat: no-repeat;
  background-size: 2.4rem 2.4rem;
  width: 2.5rem;
  height: 2.4rem;
  background-position: 95% 5%;
  margin-left: 12rem;
  margin-bottom: 8.3rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
 -ms-user-select: none;
}

.heart-icon:hover {
  background-image: url('/assets/heart-red-hover-over-half-filled.png')
}

.heart-icon:active {
    background-image: url('/assets/heart-red-filled.png')
}

.background {
  background: rgba(0,0,0,0.4);
}

.allToursWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 5rem; 
  height: "100%" 
}

.tour-wrapper {
  background: rgb(24, 11, 11);
  height: 40rem;
  min-height: 40rem;
  width: 100vw;
}

.header-wrapper {
  display: flex;
  margin: 0 auto;
  margin-left: 10vw;
  margin-right: 10vw;
}

.bg-image {
  background-image: url(/assets/running-in-forrest.jpg);
  background-repeat: no-repeat;
  background-position: top center ;
  padding-bottom: 20rem;
  width: 80rem;
  min-width: 80rem;
  height: 40rem;
  min-height: 40rem;
  
}

.tour-header {
  color: white;
  margin-left: 2rem;
}

.nav {
  display: flex;
}

.nav a {
  color: white;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: none;
  font-size: 1.3rem;
}

.nav-middle {
  margin-left: 12rem;
  margin-top: 0rem;
}

.nav-right {
  display: flex;
  justify-content: space-around;
  margin-top: 0rem;
  margin-left: 20rem;
}

.nav-right a {
  font-size: 1.2rem;
  margin-right: 0px;
}

.nav-middle a:hover,
.nav-right a:hover {
  text-decoration: underline;
}

.header-text-wrapper {
  margin-top: 5rem;
  margin-bottom: 0px;
  font-size: 3rem;
  color: white;
  margin-left: 2rem;
}

.header {
  margin: 0px;
  margin-top: 10rem;
}

.sub-header {
  margin-left: 2rem;
  color: white;
  margin-top: 0px;
}

.chevron-down {
  background-image: url(/assets/chevron-down.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 1rem;
  margin-top: 0.3rem;
  margin-left: 0.1rem;
}

.tour-information-wrapper {
  margin: 0 auto;
  width: 80rem;
  height: 40rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
}

.tour-information-left {
  display: flex;
  flex-direction: column;
}

.info-wrapper {
  margin-left: 14rem;
  margin-top: 3rem;
  display: flex;
  margin-right: 8rem;
}

.info-symbol {
  background-image: url(/assets/info-symbol.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 5rem;
  margin-top: 2rem;
  margin-left: 0.2rem;
}

.clock-wrapper {
  margin-left: 14rem;
  margin-top: 1rem;
  display: flex;
}

.clock-symbol {
  background-image: url(/assets/clock-symbol.png);
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
  margin-right: 0.1rem;
  margin-left: 0.2rem;
}

.people-wrapper {
  margin-left: 14rem;
  margin-top: 1rem;
  display: flex;
}

.people-symbol {
  background-image: url(/assets/people-symbol.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 4rem;
  margin-right: 1rem;
  margin-left: 0.4rem;
}

.description-wrapper {
  font-size: 1.4rem;
  font-weight: bold;
}

.decent-text {
  font-size: 1.2rem;
  font-weight: 500;
}

.clock-text {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  padding-right: 26.6rem;
}

.people-text {
  font-size: 1.2rem;
  font-weight: 500;
}

.note-wrapper {
  margin-left: 14rem;
  margin-top: 1rem;
  display: flex;
  margin-right: 8rem;
}

.note-symbol {
  background-image: url(/assets/note-symbol.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 5rem;
  margin-right: 1.3rem;
}

.target-wrapper {
  margin-left: 14rem;
  margin-top: 1rem;
  display: flex;
  margin-right: 8rem;
}

.target-symbol {
  background-image: url(/assets/target-symbol.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 5rem;
  margin-right: 1rem;
  margin-left: 0.2rem;
}

.tour-information-right {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.call-to-action {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid grey;
  margin-left: 2rem;
  width: 20rem;
}

.price {
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: 0rem;
  margin-bottom: 0.8rem;
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-tiny {
  font-size: 1.1rem;
}

.bookingButton {
  background-color: #7289da;
  width: 10rem;
  color: white;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.3rem;
  margin-top: 3rem;
  outline: none;
  border: none;
}

.social-media-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 0.4rem;
  width: 24rem;
}

.heart-symbol {
  background-image: url(/assets/heart.png);
  background-repeat: no-repeat;
  width: 3rem;
  height: 5rem;
}

.heart-symbol:hover {
  background-image: url(/assets/heart-red-hover-over-half-filled.png);
}

.heart-symbol:active {
  background-image: url(/assets/heart-red-filled.png);
}

.favorites {
  font-size: 1.3rem;
  font-weight: 600;
}

.avatar_dracarys {
  background-image: url(/assets/avatar_dracarys.png);
  background-repeat: no-repeat;
  width: 30rem;
  height: 13rem;
  margin-right: 1rem;
  margin-left: 5rem;
  margin-top: 2rem;
}

.avatar_reeves {
  background-image: url(/assets/avatar_reeves.png);
  background-repeat: no-repeat;
  width: 30rem;
  height: 13rem;
  margin-right: 1rem;
  margin-left: 5rem;
  margin-top: 2rem;
}

.avatar-text {
  margin-left: 7rem;
  font-size: 1.3rem;
  font-weight: 600;
}
.tiny {
    margin-left: 2rem;
    text-align: center;
}

.description-header {
  margin-left: 1.2rem;
}

.guide-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem;
}
.addTourHeader {
  background-color: white;
  height: 70vh;
}

.addTourNav {
  display: flex;
  justify-content: space-between;
}

.addTourDropdown {
  display: flex;
  margin-right: 3rem;
}

.addTourWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tourTitle {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.title {
  width: 45rem;
  margin: 1rem 0;
  padding: 1.5rem 0;
  background-color: white !important;
  color: black;
  padding-left: 0.5rem;
}

.title::-webkit-input-placeholder {
  font-size: 1.6rem;
  font-weight: bold;
  color: black;
  padding-left: 0.5rem;
}

.title::-moz-placeholder {
  font-size: 1.6rem;
  font-weight: bold;
  color: black;
  padding-left: 0.5rem;
}

.title:-ms-input-placeholder {
  font-size: 1.6rem;
  font-weight: bold;
  color: black;
  padding-left: 0.5rem;
}

.title::-ms-input-placeholder {
  font-size: 1.6rem;
  font-weight: bold;
  color: black;
  padding-left: 0.5rem;
}

.title::placeholder {
  font-size: 1.6rem;
  font-weight: bold;
  color: black;
  padding-left: 0.5rem;
}

.category {
  width: 10rem;
  color: black;
  background: white;
  padding-left: 0.5rem;
}

.category::-webkit-input-placeholder {
  color: black;
  padding-left: 0.5rem;
  font-weight: 500;
}

.category::-moz-placeholder {
  color: black;
  padding-left: 0.5rem;
  font-weight: 500;
}

.category:-ms-input-placeholder {
  color: black;
  padding-left: 0.5rem;
  font-weight: 500;
}

.category::-ms-input-placeholder {
  color: black;
  padding-left: 0.5rem;
  font-weight: 500;
}

.category::placeholder {
  color: black;
  padding-left: 0.5rem;
  font-weight: 500;
}

.addThumbnail {
  background-image: url(/assets/AddThumbnail.PNG);
  background-repeat: no-repeat;
  width: 100px;
  padding: 100px;
  margin: 0 2rem 0 0;
}

.addTourAboutWrapper {
  margin-top: 21rem;
  display: flex;
  justify-content: center;
}

.addTourAbout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 4rem;
  width: 60%;
  /* margin: 2rem; */
}

.info {
  display: flex;
  align-items: center;
}

.infoSymbol {
  background-image: url(/assets/info-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
}

.tourDescription {
  margin-bottom: 1rem;
  padding-left: 10px;
  width: 21.5rem;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  outline: none;
}

.clock {
  display: flex;
  align-items: center;
}

.clockSymbol {
  background-image: url(/assets/clock-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
}

.tourLength {
  margin-bottom: 1rem;
  width: 21.5rem;
  padding-left: 10px;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  outline: none;
}

.people {
  display: flex;
  align-items: center;
}

.peopleSymbol {
  background-image: url(/assets/people-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
}

.recommendedAge {
  margin-bottom: 1rem;
  width: 21.5rem;
  padding-left: 10px;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  outline: none;
}

.note {
  display: flex;
  align-items: center;
  padding: 0 !important;
  border: none !important;
}

.noteSymbol {
  background-image: url(/assets/note-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
  margin-left: -0.4rem;
  margin-right: 0.4rem;
}

.itemsToBring {
  margin-bottom: 1rem;
  padding-left: 10px;
  width: 21.5rem;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  outline: none;
}

.target {
  display: flex;
  align-items: center;
}

.targetSymbol {
  background-image: url(/assets/target-symbol.png);
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
}

.tourAddress {
  margin-bottom: 1rem;  
  width: 21.5rem;
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  outline: none;
}

.btnAddTour {
  margin-right: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-left: 3.9rem;
  }

.btnCancel {
  border-radius: 5px;
  padding-left: 15rem;
  padding-right: 3rem;
  font-size: 1.2rem;
}

.addTourInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tourPrice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 4rem;
}

.tourPriceInput {
  width: 2rem;
  color: black;
  padding-left: 0.3rem;
  background: #f2f2f2;
  outline: none;
  border: none;
}

.aboutGuide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.guideImage {
  background-image: url(/assets/avatar_dracarys.png);
  background-repeat: no-repeat;
  width: 15rem;
  height: 15rem;
  margin: 8rem 0 0 3rem;
}
